const mobileMenuButton = document.getElementById('mobile-menu-button');
const mobileMenu = document.getElementById('mobile-menu');
const closeMenuButton = document.getElementById('close-menu-button');
const footer = document.getElementById('footer');
const totalExperience = document.getElementById('total-experience');
const numberOfYears = document.getElementById('number-of-years');

mobileMenuButton.addEventListener('click', () => {
    mobileMenu.classList.add('scale-100');
    // mobileMenu.classList.remove('hidden');
});

closeMenuButton.addEventListener('click', () => {
    mobileMenu.classList.remove('scale-100');
});

const generateTotalExperience = () => {
    // calculate total experience and replace the text
    const startDate = new Date('2019-08-01');
    const currentDate = new Date();
    const totalExperienceInYears = currentDate.getFullYear() - startDate.getFullYear();
    totalExperience.innerHTML = totalExperience.innerHTML.replace('$totalExperience', `${totalExperienceInYears.toString()}+`);
    numberOfYears.innerHTML = numberOfYears.innerHTML.replace('$numberOfYears', totalExperienceInYears.toString());
}

generateTotalExperience();

const generateFooter = () => {
    // generate current year and replace the footer text
    const currentYear = new Date().getFullYear();
    footer.innerHTML = footer.innerHTML.replace('$year', currentYear.toString());
}

generateFooter();

console.log('Hello World!');
